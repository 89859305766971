import * as amplitude from "@amplitude/analytics-browser"
const analytics = () => {
  const pricingRoutes = ["/pricing", "/prices"]
  const signupRoutes = ["/signup", "/get-started"]
  const init = () => {
    amplitude.init(process.env.GATSBY_AMPLITUDE_API_KEY, {
      defaultTracking: {
        pageViews: false,
        sessions: false,
        formInteractions: false,
        fileDownloads: false,
      },
    })
  }

  const getUserId = () => {
    const userId = amplitude.getUserId()
    return userId ? userId : null
  }
  const getDeviceId = () => {
    const deviceId = amplitude.getDeviceId()
    return deviceId ? deviceId : null
  }

  const trackPageView = path => {
    if (pricingRoutes.includes(path)) {
      amplitude.track(EVENT_TYPES.SELF_SERVE_PRICING_PAGE_VIEW)
    }
    if (signupRoutes.includes(path)) {
      amplitude.track(EVENT_TYPES.SELF_SERVE_SIGNUP_PAGE_VIEW)
    }
  }

  const trackButtonClick = path => {
    const handleClick = e => {
      const { innerText, className } = e.target

      if (signupRoutes.includes(path)) {
        if (innerText === "pricing breakdown") {
          amplitude.track(EVENT_TYPES.SELF_SERVE_PRICING_BREAKDOWN_LINK_CLICK)
        } else if (innerText === "Check the status of your background check") {
          amplitude.track(EVENT_TYPES.SELF_SERVE_CHECK_STATUS_LINK_CLICK)
        } else if (innerText === "Sign in") {
          amplitude.track(EVENT_TYPES.SELF_SERVE_SIGNUP_LINK_CLICK)
        }
      }

      if (pricingRoutes.includes(path) && typeof className === "string") {
        if (className.includes("basic-plus-plan")) {
          amplitude.track(
            EVENT_TYPES.SELF_SERVE_PRICING_PAGE_GET_STARTED_CLICK,
            {
              "Get Started Package Type": "Basic+",
            },
          )
        } else if (className.includes("essential-plan")) {
          amplitude.track(
            EVENT_TYPES.SELF_SERVE_PRICING_PAGE_GET_STARTED_CLICK,
            {
              "Get Started Package Type": "Essential",
            },
          )
        } else if (className.includes("professional-plan")) {
          amplitude.track(
            EVENT_TYPES.SELF_SERVE_PRICING_PAGE_GET_STARTED_CLICK,
            {
              "Get Started Package Type": "Professional",
            },
          )
        } else if (className.includes("bottomModalCta")) {
          amplitude.track(EVENT_TYPES.SELF_SERVE_PRICING_PAGE_BOTTOM_CTA_CLICK)
        }
      }
      amplitude.flush()
    }

    window.addEventListener("click", handleClick)

    return () => {
      window.removeEventListener("click", handleClick)
    }
  }

  const trackFormSubmission = (formId, values, path, tier) => {
    const { Annual_Volume_of_Checks__c, timetofirstcheckMarketo, email } =
      values
    const userAttributes = {}

    if (formId) {
      userAttributes["Form ID"] = formId
    }

    if (timetofirstcheckMarketo) {
      userAttributes["Time To First Order"] = timetofirstcheckMarketo
    }

    if (Annual_Volume_of_Checks__c) {
      userAttributes["Annual Volume of Checks"] = Annual_Volume_of_Checks__c
      userAttributes["Tier"] = tier
    }

    if (email) {
      userAttributes["Email"] = email
    }

    if (!path.includes("blog")) {
      amplitude.track(EVENT_TYPES.GENERAL_FORM_SUBMISSION, userAttributes)
    }

    if (
      (formId === "1006" || formId === "1388") &&
      Annual_Volume_of_Checks__c !== "1"
    ) {
      if (pricingRoutes.includes(path)) {
        amplitude.track(
          EVENT_TYPES.SELF_SERVE_PRICING_PAGE_COMPLETED,
          userAttributes,
        )
      } else if (signupRoutes.includes(path)) {
        amplitude.track(
          EVENT_TYPES.SELF_SERVE_SIGNUP_PAGE_COMPLETED,
          userAttributes,
        )
      }
    }
    amplitude.flush()
  }

  return {
    init,
    trackPageView,
    trackButtonClick,
    trackFormSubmission,
    getUserId,
    getDeviceId,
  }
}

export { analytics }

const EVENT_TYPES = {
  SELF_SERVE_SIGNUP_PAGE_VIEW:
    "Self-Serve Customer Signup Marketing Signup Page Viewed",
  SELF_SERVE_SIGNUP_PAGE_COMPLETED:
    "Self-Serve Customer Signup Marketing Signup Page Completed",
  SELF_SERVE_PRICING_PAGE_VIEW:
    "Self-Serve Customer Signup Pricing Page Viewed",
  SELF_SERVE_PRICING_PAGE_COMPLETED:
    "Self-Serve Customer Signup Pricing Page Modal Completed",
  SELF_SERVE_PRICING_BREAKDOWN_LINK_CLICK:
    "Self-Serve Customer Signup Marketing Signup Page Price Breakdown Clicked",
  SELF_SERVE_CHECK_STATUS_LINK_CLICK:
    "Self-Serve Customer Signup Marketing Signup Page Check Status Clicked",
  SELF_SERVE_SIGNUP_LINK_CLICK:
    "Self-Serve Customer Signup Marketing Signup Page Sign In Clicked",
  SELF_SERVE_PRICING_PAGE_GET_STARTED_CLICK:
    "Self-Serve Customer Signup Pricing Page Get Started Clicked",
  SELF_SERVE_PRICING_PAGE_BOTTOM_CTA_CLICK:
    "Self-Serve Customer Signup Pricing Page Bottom Sign Up Now Clicked",
  GENERAL_FORM_SUBMISSION: "Self-Serve Marketing Signup Page Completed",
}
