import React from "react"

export const TrackingContext = React.createContext()

const TrackingContextProvider = ({ children }) => {
  const [trackingData, setTrackingData] = React.useState(null)
  const [impactScriptLoaded, setImpactScriptLoaded] = React.useState(false)
  const [customerUUID, setCustomerUUID] = React.useState("")

  const updateTrackingData = React.useCallback(data => {
    setTrackingData(data)
  }, [])

  const loadImpactScript = React.useCallback(() => {
    setImpactScriptLoaded(true)
  }, [])

  const value = React.useMemo(
    () => ({
      trackingData,
      updateTrackingData,
      impactScriptLoaded,
      loadImpactScript,
      customerUUID,
    }),
    [
      trackingData,
      updateTrackingData,
      impactScriptLoaded,
      loadImpactScript,
      customerUUID,
    ],
  )

  React.useEffect(() => {
    if (!customerUUID) {
      const storedUUID = localStorage.getItem("customerUUID")
      const uuid = crypto.randomUUID()

      if (storedUUID) {
        setCustomerUUID(storedUUID)
      } else {
        localStorage.setItem("customerUUID", uuid)
        setCustomerUUID(uuid)
      }
    }
  }, [customerUUID])

  React.useEffect(() => {
    const handleConsentInteraction = event => {
      setTimeout(() => {
        window.location.reload()
      }, 700)
    }

    window.addEventListener("OTConsentApplied", handleConsentInteraction, {
      once: true,
    })
  }, [])

  return (
    <TrackingContext.Provider value={value}>
      {children}
    </TrackingContext.Provider>
  )
}

export default TrackingContextProvider

export { TrackingContextProvider }
