import { Experiment } from "@amplitude/experiment-js-client"

function experiments() {
  const apiKey = process.env.GATSBY_AMPLITUDE_DEPLOY_KEY
  const experiment = Experiment.initializeWithAmplitudeAnalytics(apiKey)

  const start = async () => {
    return await experiment.start()
  }

  const getVariant = flagKey => {
    return experiment.variant(flagKey, { value: "control" })
  }

  return {
    start,
    getVariant,
  }
}

export { experiments }
